import React from 'react'
import './About.css'
import Img1 from '../../../utiles/images/k11.jpg'
import Img2 from '../../../utiles/images/b3.jpg'
import { PiUsersThreeBold } from "react-icons/pi";
import { LiaAwardSolid } from "react-icons/lia";

const About = () => {
  return (
    <>
      <div className="container-fluid contaiener-fluid-gray "  >

        <div className="container" style={{ paddingTop: '40px', paddingBottom: '40px' }} >
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6 about-content ">
              <h1 style={{ marginBottom: '29px' }} >About Company</h1>
              <p>
                we are passionate about transforming houses into homes. With a deep-rooted commitment to excellence and over many years of industry experience, we have become a trusted name in the world of home remodeling.
                Our mission is simple yet profound: to turn your kitchen and bathroom dreams into reality. We understand that these spaces are the heart of your home, and we take pride in enhancing their beauty, functionality, and comfort.
                We are dedicated to delivering the highest quality craftsmanship and materials. Your satisfaction is our priority, and we won't rest until you are delighted with the final results.
                We offer budget-friendly options without compromising on the quality of the work.

                Our Services:

                Kitchen Remodeling: From updating fixtures to complete overhauls, we create kitchens that are not only functional but also stunning in design.
                Bathroom Remodeling: Transform your bathroom into a personal oasis with our remodeling services. We pay meticulous attention to every detail, ensuring your bathroom is both beautiful and practical.

              </p>
              <p>
                Experience the difference with us. We believe that a well-designed kitchen and bathroom can have a transformative impact on your daily life. By choosing us, you're choosing a partner who is dedicated to making your home the best it can be.
              </p>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
              <div className="about-img">
                <img className='about-img-1 shadow ' src={Img1} alt="" />
                <img className='about-img-2 shadow ' src={Img2} alt="" />
                <div className="about-line-1">
                  <div className="about-line-box"><PiUsersThreeBold /></div>
                  <h1>We have more than 10 years of experiences</h1>
                </div>
                <div className="about-line-2">
                  <div className="about-line-box"><LiaAwardSolid /></div>
                  <h1>We use professional and experienced person</h1>
                </div>
              </div>
            </div>
            <div className="col-12 about-content-2 ">
              <p>

                Our team of skilled craftsmen and designers brings a wealth of expertise to every project. We stay updated with the latest industry trends and technologies to ensure you receive top-notch solutions. We work closely with you to understand your vision and deliver tailor-made solutions that reflect your style and needs
              </p>
            </div>
          </div>
        </div>

      </div>
    </>
  )
}

export default About