import React from 'react'
import './Alllanding.css'
import Circular from '../circular/Circular'


const Alllanding = ({ContactData}) => {
  const {img,heading}=ContactData[0]
  // console.log(ContactData,'heading .....');
  return (
    <>
    <div className='container alllanding_container' >
         <div className="overlay"></div>
         <div className="alllanding_image" style={{backgroundImage:`url(${img})`}} ></div>
         <div className="main_heading">
          <h1>{heading}</h1>
         </div>
    </div>
    {/* <Circular/> */}
    </>
  )
}

export default Alllanding