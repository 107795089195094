import React, { useEffect, useRef } from "react";
import Navbar from "../../components/common/navbar/Navbar";
import Hero from "../../components/home/hero/Hero";
// import Hero2 from '../../components/home/hero/Hero2'
import Team from "../../components/home/team/Team";
import Footer from "../../components/common/fotter/Fotter";
import Tottal from "../../components/home/tottal/Tottal";
import Qoute from "../../components/home/qoute/Qoute";
import About from "../../components/home/about/About";
import { useLocation } from "react-router-dom";
import Priceing from "../../components/Priceing/Priceing";
import Why from "../../components/home/Why/Why";
import Projectslider from "../../components/home/projectslider/Projectslider";
import Trust from "../../components/home/trust/Trust";
import Clientreview from "../../components/about/clientreivew/Clientreview";
import Faq from "../../components/about/faq/Faq";
import { Affix, Button } from "antd";
import { AiFillPhone } from "react-icons/ai";
import { FloatButton } from "antd";
import Alllanding from "../../components/common/alllanding/Alllanding";
import ServicesModule from '../../components/services/ServicesModule'
import img1 from '../../../src/utiles/images/carousel-2.jpg'

const Home = () => {
  const location = useLocation();

  useEffect(() => {
    console.log(location.state?.bol, "bol");
  }, [location.state]);

  const qouteRef = useRef(); // Create a ref for the Qoute component
  const ContactData = [
    {
      heading: 'Our Services',
      img: img1
    }
  ]
  return (
    <>
      <div style={{ width: "100%", overflow: "hidden" }}>
        {/* <Navbar /> */}
        <Hero />
        <About />
        <Why />
        {/* <Trust /> */}
        {/* <Team /> */}
        {/* <Priceing/> */}
        {/* <Projectslider /> */}
        {/* <Tottal /> */}
        <Alllanding ContactData={ContactData} />
        <ServicesModule />
        <Qoute ref={qouteRef} />

        <Clientreview />
        <Faq />

        <Footer />
        {/* <Affix offsetBottom={20} offsetX={1000}>

          <Button
            type="primary"
            // shape="round"
            // size="large"
            style={{borderRadius:"50%", padding:"10px"}}
            onClick={() => {
              window.open(`tel:250-863-1229`);
            }}
          >
            <AiFillPhone size="30px"/>
          </Button>
        </Affix> */}
        {/* <FloatButton  icon={<AiFillPhone />} onClick={() => {
              window.open(`tel:250-863-1229`);
            }}/> */}
        <FloatButton 
          icon={<AiFillPhone style={{ fontSize: '24px' }} />} 
          onClick={() => {
            window.open(`tel:250-863-1229`);
          }}
          style={{ fontSize: '24px', width: '70px', height: '70px', lineHeight: '70px' }}
        />
      </div>
    </>
  );
};

export default Home;
