import React, { useRef } from "react";
import "./Qoute.css";
import emailjs from "@emailjs/browser";
import { Button, message, Space } from "antd";

const Qoute = () => {
  const form = useRef();

  const [messageApi, contextHolder] = message.useMessage();

  const success = () => {
    messageApi.open({
      type: "success",
      content: "Email Sent!",
    });
  };

  const error = () => {
    messageApi.open({
      type: "error",
      content: "An error occoured!",
    });
  };

  const warning = () => {
    messageApi.open({
      type: "warning",
      content: "This is a warning message",
    });
  };

  const sendEmail = (e) => {
    console.log("ji")
    e.preventDefault();

    emailjs
      .sendForm("service_d8uf6m8", "template_h4g2j3p", e.target, {
        subject: "General Inquiry",
        publicKey: "qJIBdGNJQz2j-fJ8h",
      })
      .then(
        () => {
          console.log("SUCCESS!");
          success();
          form.current.reset();
        },
        (error) => {
          console.log("FAILED...", error.text);
          error();
        }
      );
  };

  return (
    <>
      {contextHolder}

      <div className="container qoute-container-main ">
        <div className="qoute-header">
          <h1>Get Qoute</h1>
          <p>
            Ready to start your dream bathroom or kitchen remodeling project?
            Fill out the form below to request a free, no-obligation quote. Our
            team of experienced professionals is here to help you bring your
            vision to life.
          </p>
        </div>
        <div className="qoute-overlay"></div>
        <div className="qoute-content-main">
          <div className="qoute-send-main">
            <form ref={form} onSubmit={sendEmail}>
              <div className="qoute-entity">
                <label htmlFor="">Enter your name</label>
                <input placeholder="Name" type="text" name="name" />
              </div>

              <div className="qoute-entity">
                <label htmlFor="">Enter your email</label>
                <input placeholder="E-mail" type="email" name="email" />
              </div>

              <div className="qoute-entity">
                <label htmlFor="">Send message</label>
                <textarea
                  placeholder="Message"
                  name="message"
                  id=""
                  cols="30"
                  rows="10"
                ></textarea>
              </div>

              <button type="submit">Submit</button>
            </form>
          </div>

          <div className="qoute-img"></div>
        </div>
      </div>
    </>
  );
};

export default Qoute;
