import React, { useState } from 'react'
import './Why.css'

import { why } from '../../../utiles/data'

const Why = () => {

    const [data, setData] = useState(why)

    const render = data.map((items) => {
        const {heading,typo,icon}=items
        return (
            <>
                <div className="col-sm-12 col-md-12 col-lg-4 col-xl-4">
                    <div className='why-card' >
                        <div className="why-card-a">
                            <div className="why-card-box">
                                <h1>
                                {
                                    icon
                                }
                                </h1>
                            </div>
                        </div>
                        <div className="why-card-b">
                            <h1>{heading}</h1>
                            <p>{typo}</p>
                        </div>
                    </div>
                </div >
            </>
        )
    })

    return (
        <>
            <div style={{marginTop:'50px'}} className="container">
            <div className="row  d-flex-sm d-flex-md justify-content-center align-items-center">
                    <h1>Why Chouse Us</h1>
                    <p>
                    we understand that choosing a remodeling partner for your kitchen and bathroom projects is a significant decision. Here are some compelling reasons to choose us:
                    </p>
                </div>
                <div className="row why-render "  >

                    {
                        render
                    }

                </div>
            </div>
        </>
    )
}

export default Why