import { React, useState, useEffect } from 'react'
import './Hero.css'
// import { Carousel } from 'antd';
import { Carousel, Button } from 'antd';
import carousel1 from '../../../utiles/images/b8.jpg'
import carousel2 from '../../../utiles/images/k3.jpg'

const Hero = () => {

    const subHeading = 'Static Subheading'; // Define the static subheading

    // Replace this with your dynamic data
    const sliderData = [
        {
            imgSrc: carousel1,
            //   imgSrc: 'image1.jpg',
            mainHeading: 'Revamp your aging area into a stylish haven with our professional remodeling services.',
            typo:'Elevate your surroundings and embrace a renewed sense of style with our top-notch remodeling expertise.'
        },
        {
            imgSrc: carousel2,
            //   imgSrc: 'image2.jpg',
            mainHeading: 'Transform your living space into a sophisticated retreat with our premier remodeling services.    ',
            typo:'Revitalize your environment and experience a fresh perspective on comfort and style with our expert remodeling solutions.',
        },
        {
            imgSrc:carousel1,
            mainHeading: 'Revamp your space with our skilled remodeling services, turning ordinary into extraordinary.',
            typo: 'Rediscover the potential of your surroundings as we breathe new life into your space with our expert remodeling touch.'
        }

        // Add more slides as needed
    ];

    const [activeIndex, setActiveIndex] = useState(0);
    const [shouldFadeOut, setShouldFadeOut] = useState(false);

    const handleNextSlide = () => {
        setShouldFadeOut(true); // Trigger fading when changing the slide
        setTimeout(() => {
            setActiveIndex((prevIndex) => (prevIndex + 1) % sliderData.length);
            setShouldFadeOut(false); // Reset fading
        }, 1000); // Change slide after 1 second (you can adjust this timing)
    };

    const handlePrevSlide = () => {
        setShouldFadeOut(true); // Trigger fading when changing the slide
        setTimeout(() => {
            setActiveIndex((prevIndex) =>
                prevIndex === 0 ? sliderData.length - 1 : prevIndex - 1
            );
            setShouldFadeOut(false); // Reset fading
        }, 1000);
    };

    return (
        <>
            <div className="header">
                <Carousel
                    autoplay={true}
                    effect="fade"
                    beforeChange={() => setShouldFadeOut(true)}
                    afterChange={() => setShouldFadeOut(false)}
                >
                    {sliderData.map((slide, index) => (
                        <div
                            key={index}
                            className={`slider-item ${index === activeIndex ? 'active' : ''}`}
                        >
                            <img src={slide.imgSrc} alt={slide.mainHeading} />
                            <div className="overlay"></div>
                            <div className="caption">
                                <h1 className={`main-heading ${shouldFadeOut ? 'fade-out' : ''}`}>
                                    {slide.mainHeading}
                                </h1>
                                <div className={`Story_All_Tags ${shouldFadeOut ? 'fade-right' : ''} `}>
                                        <p>
                                            {
                                                slide.typo
                                            }
                                        </p>
                                    {/* Lorem ipsum dolor sit amet consectetur adipisicing elit. Tenetur laborum, provident ipsum odit delectus quos sunt tempore ipsa, rem, beatae laudantium adipisci voluptatem? Vero minus illum eligendi ullam sit commodi? */}
                                </div>
                            </div>
                        </div>
                    ))}
                </Carousel>
            </div>
        </>
    )
}

export default Hero