import { React, useState, useEffect } from 'react'
import './Projectslider.css'
import Slider from 'react-slick'

// import { TeamData } from '../../../utiles/data';

import { Projects } from '../../../utiles/data'

// Import css files
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import useItems from 'antd/es/menu/hooks/useItems';
import { AiFillLeftCircle, AiFillRightCircle } from "react-icons/ai";
import { useNavigate } from 'react-router-dom';

const minWidth = 1100; // Define your minimum width threshold
const isGreaterThanMinWidth = window.innerWidth >= minWidth;

const minWidth2 = 800; // Define your minimum width threshold
const isGreaterThanMinWidth2 = window.innerWidth >= minWidth2;

const minWidth3 = 500; // Define your minimum width threshold
const isGreaterThanMinWidth3 = window.innerWidth >= minWidth3;

const Projectslider = () => {

    const [index, setindex] = useState(0);

    const navigate = useNavigate()

    const SampleNextArrow = ({ onClick }) => {
        return (
            <div className="nextMf" onClick={onClick}>
                <AiFillLeftCircle className={`leani`} />
            </div>
        );
    };

    const SamplePrevArrow = ({ onClick }) => {
        return (
            <div className="prevMf" onClick={onClick}>
                <AiFillRightCircle className={`riani`} />
            </div>
        );
    };


    const settings = {

        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: isGreaterThanMinWidth ? 3 : isGreaterThanMinWidth2 ? 3 : isGreaterThanMinWidth3 ? 2 : 1,
        // slidesToShow: 4,
        lazyload: true,
        centerMode: "true",
        centerPadding: 0,
        beforeChange: (current, next) => setindex(next),
        autoplay: true,
        autoplaySpeed: 2000,
        cssEase: "linear",
        // nextArrow: <SampleNextArrow />,
        // prevArrow: <SamplePrevArrow />,

    };


    const gallery=()=>{
         navigate('/gallery')
    }

    return (
        <div style={{paddingBottom:"20px"}}>
            <div className="container-fluid projectslider-main " style={{paddingTop:'40px'}} >
                <div className="row">
                    <div className="col-12 d-flex justify-content-center align-items-center flex-column">
                        <h1>Our Remodeling Projects</h1>
                        <p>we take pride in transforming your bathroom and kitchen spaces into functional, beautiful, and customized areas that perfectly align with your vision and needs.</p>
                        <div className="hr"></div>
                    </div>
                    <div className="col-12">
                        <Slider {...settings}>
                            {Projects.map((item, i) => (
                                <div className='slick_card_layout ' key={item.id}>
                                    <div className="projectslider_card " style={{ backgroundImage: `url(${item.img})` }} >
                                        <div className="projectslider_overlay"></div>
                                        <div className="projectslider_content">
                                            <div className="projectslider_heading">{item.heading}</div>
                                            <div className="projectslider_typo">{item.typo}</div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    </div>
                    <div style={{ marginTop: '120px' }} className=" project-slider-btn-main col-12 d-flex justify-content-center align-items-center ">
                        <button onClick={gallery} >View All</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Projectslider