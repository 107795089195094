import React from 'react'
import Navbar from './components/common/navbar/Navbar'
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './pages/home/Home';
import Contact from './pages/contact/Contact';
import About from './pages/about/About';
import Services from './pages/services/Services';
import Qoute from './pages/qoute/Qoute';
import Gallery from './pages/gallery/Gallery';
import Gallerypage from './pages/gallerypage/Gallerypage';
// import Circular from './components/common/circular/Circular';


const App = () => {
  return (
    <>
      {/* <Navbar/> */}
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home/>}/>
          {/* <Route path="/contact" element={<Contact/>}/>
          <Route path="/about" element={<About/>}/>
          <Route path="/services" element={<Services/>}/>
          <Route path="/qoute" element={<Qoute/>}/>
          <Route path="/gallery" element={<Gallerypage/>}/> */}
        </Routes>
      </BrowserRouter>

      {/* /// */}

      {/* <Circular/> */}

    </>
  )
}

export default App