

import { AiFillTool } from "react-icons/ai";
import { HiPencil } from "react-icons/hi2";
import { HiHomeModern } from "react-icons/hi2";
import { BsFillBuildingsFill } from "react-icons/bs";
import { AiFillReconciliation } from "react-icons/ai";

import { BsFacebook, BsTwitter } from "react-icons/bs";
import { MdOutlineEngineering } from "react-icons/md";
import { GiPencilRuler } from "react-icons/gi";
import { MdSupervisedUserCircle } from "react-icons/md";

import { PiCompassToolBold, PiStoolBold, PiLightbulbBold, PiTimerBold } from "react-icons/pi";

import { IoMdHappy } from "react-icons/io";

import { MdApproval } from "react-icons/md";

import { TbBathFilled } from "react-icons/tb";

import { BiSolidCabinet } from "react-icons/bi";

import { MdLightbulbCircle } from "react-icons/md";

//////
import k1 from '../utiles/images/k1.jpg'
import k2 from '../utiles/images/k2.jpg'
import k3 from '../utiles/images/k3.jpg'
import k4 from '../utiles/images/k4.jpg'
import k5 from '../utiles/images/k5.jpg'
import k6 from '../utiles/images/k6.jpg'
import k9 from '../utiles/images/k9.jpg'

import b1 from '../utiles/images/b1.jpg'
import b2 from '../utiles/images/b2.jpg'
import b3 from '../utiles/images/b3.jpg'
// import b4 from '../utiles/images/b4.jpg'
import b5 from '../utiles/images/b5.jpg'
import b6 from '../utiles/images/b6.jpg'
//////

import ka1 from '../utiles/images/ka1.webp'
import ka2 from '../utiles/images/ka2.png'
import ka3 from '../utiles/images/ka3.jpg'
import ka4 from '../utiles/images/ka4.png'

//////

import ba1 from '../utiles/images/ba1.jpg'
import ba2 from '../utiles/images/ba2.jpg'
import ba3 from '../utiles/images/ba3.jpg'
import ba4 from '../utiles/images/ba4.webp'

//  Home page Carousle Data ///////////

export const CarouselData = [
  {
    img: 'https://images.unsplash.com/photo-1695605302505-32372d93493f?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHw1fHx8ZW58MHx8fHx8&auto=format&fit=crop&w=500&q=60',
    heading: 'Heading One',
    paragraph: 'this is paragraph of the year .'
  },
  {
    img: 'https://images.unsplash.com/photo-1695605302505-32372d93493f?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHw1fHx8ZW58MHx8fHx8&auto=format&fit=crop&w=500&q=60',
    heading: 'Heading Two',
    paragraph: 'this is paragraph of the year .'
  },
  {
    img: 'https://images.unsplash.com/photo-1695605302505-32372d93493f?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHw1fHx8ZW58MHx8fHx8&auto=format&fit=crop&w=500&q=60',
    heading: 'Heading Three',
    paragraph: 'this is paragraph of the year .'
  },
  {
    img: 'https://images.unsplash.com/photo-1695605302505-32372d93493f?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHw1fHx8ZW58MHx8fHx8&auto=format&fit=crop&w=500&q=60',
    heading: 'Heading Four',
    paragraph: 'this is paragraph of the year .'
  },
]

////// insta Data ///////

export const InstaData = [
  {
    img: b1,
  },
  {
    img: k1
  },
  {
    img:k2
  },
  {
    img:b2
  },
  {
    img:k4
  },
  {
    img:b5
  },
]

// servise data

export const ServiceData = [
  {
    icon: <BiSolidCabinet />,
    heading: 'Residencial Renovation',
    paragrapgh: 'Elevate the charm and functionality of your home with our Residential Renovation services. From modern updates to timeless transformations, our skilled team is dedicated to revitalizing your living space. Experience the seamless blend of innovation and craftsmanship as we bring new life to your home, creating an environment that reflects your style and meets your evolving needs. Trust us to turn your residential vision into a reality with our comprehensive and tailored renovation solutions.',
    img: k9,
  },
  {
    icon: <MdLightbulbCircle />,
    heading: ' Roofing',
    paragrapgh: 'Discover unparalleled expertise and quality with our Roofing Services. Our team is committed to safeguarding your home with top-notch roofing solutions that prioritize durability and aesthetic appeal. Whether you\'re in need of repairs, replacements, or a complete roofing overhaul, we bring years of experience to every project. Trust us to shield your home from the elements while enhancing its curb appeal. Elevate your property with our reliable and professional roofing services, ensuring peace of mind under every weather condition.',
    img:k2,
  },
  {
    // icon: <AiFillTool />,
    icon: < HiPencil />,
    heading: 'Design Consultation',
    paragrapgh: ' Our team works closely with you to understand your vision and preferences. We offer expert guidance in choosing the right fixtures, tiles, and colors to create your dream bathroom. ',
    img: 'https://images.unsplash.com/photo-1618090584176-7132b9911657?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8YnVpbGRlcnxlbnwwfHwwfHx8MA%3D%3D&auto=format&fit=crop&w=500&q=60'
  },
  {
    // icon: < HiPencil />,
    icon: <AiFillTool />,
    heading: ' Fixture Upgrades',
    paragrapgh: 'We can replace or upgrade your bathroom fixtures, including sinks, faucets, toilets, and bathtubs, with high-quality, stylish options. ',
    img: 'https://images.pexels.com/photos/3760529/pexels-photo-3760529.jpeg?auto=compress&cs=tinysrgb&w=600'
  },
  {
    icon: < TbBathFilled />,
    heading: 'Shower and Tub Installation',
    paragrapgh: 'Whether you want a luxurious bathtub or a modern, spacious shower, we can design and install the perfect solution for your space. ',
    img: b1,
  },

  {
    icon: <HiHomeModern />,
    heading: 'Tile and Flooring',
    paragrapgh: ' From beautiful mosaic tiles to durable, easy-to-maintain flooring options, we provide a wide range of choices to suit your style and needs. ',
    img: 'https://images.pexels.com/photos/8961336/pexels-photo-8961336.jpeg?auto=compress&cs=tinysrgb&w=600',
  },
]

export const TeamData = [
  { img: 'https://images.unsplash.com/photo-1584043720379-b56cd9199c94?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MjB8fG1lbnxlbnwwfHwwfHx8MA%3D%3D&auto=format&fit=crop&w=500&q=60', id: 1, name: 'John', icon: <MdOutlineEngineering />, field: 'Engineer' },
  { img: 'https://images.unsplash.com/photo-1560250097-0b93528c311a?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MjN8fG1lbnxlbnwwfHwwfHx8MA%3D%3D&auto=format&fit=crop&w=500&q=60', id: 2, name: 'Alice', icon: <GiPencilRuler />, field: 'Designer' },
  { img: 'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTJ8fG1lbnxlbnwwfHwwfHx8MA%3D%3D&auto=format&fit=crop&w=500&q=60', id: 3, name: 'Bob', icon: <MdSupervisedUserCircle />, field: 'Manager' },
  { img: 'https://images.unsplash.com/photo-1610088441520-4352457e7095?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTh8fG1lbnxlbnwwfHwwfHx8MA%3D%3D&auto=format&fit=crop&w=500&q=60', id: 4, name: 'John', icon: <GiPencilRuler />, field: 'Designer' },
  { img: 'https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTl8fG1lbnxlbnwwfHwwfHx8MA%3D%3D&auto=format&fit=crop&w=500&q=60', id: 5, name: 'Jimmy', icon: <MdOutlineEngineering />, field: 'Engineer' },
  { img: 'https://images.unsplash.com/photo-1506634572416-48cdfe530110?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mjh8fG1lbnxlbnwwfHwwfHx8MA%3D%3D&auto=format&fit=crop&w=500&q=60', id: 6, name: 'Sam', icon: <MdOutlineEngineering />, field: 'Engineer' },
  { img: 'https://images.unsplash.com/photo-1488161628813-04466f872be2?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MzB8fG1lbnxlbnwwfHwwfHx8MA%3D%3D&auto=format&fit=crop&w=500&q=60', id: 7, name: 'Poule', icon: <MdOutlineEngineering />, field: 'Engineer' },
  { img: 'https://images.unsplash.com/photo-1556157382-97eda2d62296?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NDB8fG1lbnxlbnwwfHwwfHx8MA%3D%3D&auto=format&fit=crop&w=500&q=60', id: 8, name: 'Josaf', icon: <MdOutlineEngineering />, field: 'Engineer' },
  { img: 'https://images.unsplash.com/photo-1559893088-c0787ebfc084?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MzJ8fG1lbnxlbnwwfHwwfHx8MA%3D%3D&auto=format&fit=crop&w=500&q=60', id: 9, name: 'Herry', icon: <MdOutlineEngineering />, field: 'Engineer' },
  // Add more data items as needed
];

export const faq = [
  {
    question: 'What types of construction projects do you specialize in?',
    ans: 'We specialize in a wide range of construction projects, including residential, commercial, and industrial buildings. Our expertise includes new construction, renovations, remodels, and more.',
  },
  {
    question: 'How do I request a quote for my project?',
    ans: `You can request a quote by filling out the contact form on our website or by calling our office. We'll schedule a consultation to discuss your project's details and provide you with an accurate estimate.`,
  },
  {
    question: 'Can you help with the design phase of my project?',
    ans: 'Absolutely! We offer design and planning services to help bring your vision to life. Our experienced team can assist with architectural design, engineering, and obtaining necessary permits.',
  },
  {
    question: 'Are you licensed and insured?',
    ans: 'Yes, we are fully licensed and insured. We comply with all local regulations and carry the necessary insurance to protect both our team and your project.',
  },
  {
    question: 'How long will my project take to complete?',
    ans: 'The timeline for each project varies based on its size and complexity. During our initial consultation, we will provide you with an estimated timeline, and we strive to complete projects efficiently without compromising quality.',
  },
]

export const why = [
  {
    heading: 'Expertise & Experience',
    typo: 'Our team of skilled craftsmen and designers brings a wealth of expertise to every project.',
    icon: <PiCompassToolBold />
  },
  {
    heading: 'Master Builder',
    typo: 'We take pride in being master builders. Our commitment to excellence and attention to detail sets us apart. ',
    icon: <PiStoolBold />
  },
  {
    heading: 'Management',
    typo: 'Our efficient project management ensures that your kitchen and bathroom remodeling stays on track. ',
    icon: <PiLightbulbBold />
  },
  {
    heading: 'On Time On Budget',
    typo: 'We understand the importance of staying on time and on budget. ',
    icon: <PiTimerBold />
  },
  {
    heading: 'Customer Satisfaction',
    typo: 'Your satisfaction is our top priority. We are dedicated to delivering projects that leave homeowners thrilled with the results. ',
    icon: <IoMdHappy />
  },
  {
    heading: 'Quality Assurance',
    typo: 'We are committed to delivering the highest quality craftsmanship and materials. ',
    icon: <MdApproval />
  }
];

export const Projects = [
  {
    img: k1,
    heading: 'Building No 1',
    typo: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.'
  },
  {
    img: b1,
    heading: 'Building No 2',
    typo: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.'
  },
  {
    img: k2,
    heading: 'Building No 3',
    typo: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.'
  },
  {
    img: b2,
    heading: 'Building No 4',
    typo: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.'
  },
  {
    img: k3,
    heading: 'Building No 5',
    typo: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.'
  },
  {
    img: b3,
    heading: 'Building No 6',
    typo: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.'
  },
  {
    img: k4,
    heading: 'Building No 7',
    typo: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.'
  },
  {
    img: b5,
    heading: 'Building No 8',
    typo: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.'
  },
  {
    img: k5,
    heading: 'Building No 9',
    typo: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.'
  },
  {
    img: k6,
    heading: 'Building No 10',
    typo: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.'
  },

  /////

  {
    img: ka1,
    heading: 'Building No 10',
    typo: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.'
  },

  {
    img: ba1,
    heading: 'Building No 10',
    typo: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.'
  },

  {
    img: ka2,
    heading: 'Building No 10',
    typo: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.'
  },

  {
    img: ba2,
    heading: 'Building No 10',
    typo: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.'
  },

  {
    img: ka3,
    heading: 'Building No 10',
    typo: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.'
  },

  {
    img: ba3,
    heading: 'Building No 10',
    typo: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.'
  },

  {
    img: ka4,
    heading: 'Building No 10',
    typo: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.'
  },

  {
    img: ba4,
    heading: 'Building No 10',
    typo: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.'
  },




]


