import React, { useState } from 'react'
import './ServicesModule.css'
import { ServiceData } from '../../utiles/data'

const Services = () => {

  const [data, setData] = useState(ServiceData);

  const result = data.map((items, index) => {
    const { img, heading, paragrapgh, icon } = items
    return (
      <>
        <div className="col-12" style={{ marginTop: '30px', marginBottom: '30px' }} >
          {
            index % 2 == 0 ?
              <div className="row services_slide">
                <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6 order-sm-2 order-md-2">
                  <div style={{ backgroundImage: `url(${img})` }} className="fancy"></div>
                </div>
                <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6 order-sm-1 order-md-1 services_content ">
                  <h2 className='fancy_icon'>{icon}</h2>
                  <h1>{heading}</h1>
                  <p style={{ marginTop: '25px' }}>{paragrapgh}</p>
                </div>
              </div>
              :
              <div className="row services_slide ">
                <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                  <div style={{ backgroundImage: `url(${img})` }} className="fancy"></div>
                </div>
                <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6 services_content ">
                  <h2 className='fancy_icon' >{icon}</h2>
                  <h1>{heading}</h1>
                  <p style={{ marginTop: '25px' }} >{paragrapgh}</p>
                </div>
              </div>
          }
        </div>
      </>
    )
  })

  return (

    <>
      <div className="container">
        <div className="row">
          {
            result
          }
        </div>
      </div>

    </>

  )
}

export default Services