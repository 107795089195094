import { React, useState } from 'react';
import './Team.css';
import Slider from 'react-slick';
import { TeamData } from '../../../utiles/data';


// Import css files
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import useItems from 'antd/es/menu/hooks/useItems';
import { AiFillLeftCircle,AiFillRightCircle } from "react-icons/ai";

const minWidth = 1100; // Define your minimum width threshold
const isGreaterThanMinWidth = window.innerWidth >= minWidth;

const minWidth2 = 800; // Define your minimum width threshold
const isGreaterThanMinWidth2 = window.innerWidth >= minWidth2;

const minWidth3 = 500; // Define your minimum width threshold
const isGreaterThanMinWidth3 = window.innerWidth >= minWidth3;

const Team = () => {

    const [index, setindex] = useState(0);

    const SampleNextArrow = ({ onClick }) => {
        return (
          <div className="nextMf" onClick={onClick}>
            <AiFillLeftCircle className={`leani`} />
          </div>
        );
      };
    
      const SamplePrevArrow = ({ onClick }) => {
        return (
          <div className="prevMf" onClick={onClick}>
            <AiFillRightCircle className={`riani`} />
          </div>
        );
      };


    const settings = {

        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: isGreaterThanMinWidth ? 3 : isGreaterThanMinWidth2 ? 3 : isGreaterThanMinWidth3 ? 2 : 1,
        lazyload: true,
        centerMode: "true",
        centerPadding: 0,
        beforeChange: (current, next) => setindex(next),
        autoplay: true,
        autoplaySpeed: 2000,
        cssEase: "linear",
        // nextArrow: <SampleNextArrow />,
        // prevArrow: <SamplePrevArrow />,

    };

    return (

        <div className="container Team_Container" style={{ marginTop: '40px' }} >
            <div className="row">
                <div className="col-12"><h1>Our Team</h1><p style={{ width: '60%' }} >Our team is a collective force of expertise, passion, and creativity</p></div>
                <Slider {...settings}>
                    {TeamData.map((item, i) => (
                        <div className='slick_card_layout ' key={item.id}>
                            <div className="slick_card">
                                <div className="slick_bottom shadow ">
                                    <div className={`slick_content shadow-sm ${i === index ? "FA-Max" : "FA-Min"}`}>
                                        <img className="slick-img" src={item.img} />
                                        <h3>{item.name}</h3>
                                        <h6>{item.field}</h6>
                                        <div className="slick_curve">{item.icon}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </Slider>
            </div>
        </div>


    );
};

export default Team;
