
import React from 'react'
import { faq } from '../../../utiles/data'
import { MDBAccordion, MDBAccordionItem } from 'mdb-react-ui-kit';
import { GrBarChart } from "react-icons/gr";

const Faq = () => {

   const Render = faq.map((item,index) => {
        const {question,ans}=item
        return (
            <>
                {/* <MDBAccordionItem style={{ marginTop: '20px',marginBottom:'20px' }} collapseId={index} headerTitle={` ${<GrBarChart/>} ${question}`}> */}
                <MDBAccordionItem style={{ marginTop: '20px',marginBottom:'20px' }} collapseId={index} headerTitle={` ${question}`}>
                    {ans}
                </MDBAccordionItem>
            </>
        )
    })

    return (
        <>
            <MDBAccordion className='container' initialActive={1}>

                     {
                        Render
                     }

            </MDBAccordion>
        </>
    )
}

export default Faq
