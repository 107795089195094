import React from "react";
import { InstaData } from "../../../utiles/data";
import "./Fotter.css";
import { useNavigate } from "react-router-dom";
import fotterimg from "../../../../src/utiles/images/fotterbg.png";

import fot2 from "../../../../src/utiles/images/fot2.png";

import logo from "../../../utiles/images/logo.png";

const Fotter = () => {
  const navigate = useNavigate();

  const nextpage = (val) => {
    navigate(val);
  };

  ////// InstaFeed //////

  const instafeed = InstaData.map((data, index) => {
    const { img } = data;
    return (
      <>
        <li>
          <a>
            <img class="img-fluid-fotter" src={img} alt="" />
          </a>
        </li>
      </>
    );
  });

  const gotoservice = () => {
    navigate("/services");
  };

  return (
    <>
      <footer>
        <div
          class="footer-wrapper "
          style={{ backgroundImage: `url(${fot2})` }}
        >
          {/* <!-- Footer Start--> */}
          <div class="footer-area footer-padding">
            <div class="container">
              <div class="row justify-content-between">
                <div class="col-xl-4 col-lg-4 col-md-5 col-sm-7">
                  <div class="single-footer-caption mb-50">
                    <div class="single-footer-caption mb-30">
                      {/* <!-- logo --> */}
                      <div
                        class="footer-logo mb-35"
                        style={{ marginTop: "10px"}}
                      >
                        <a style={{ color: "rgb(232, 232, 232)" }}>
                          <img src={logo} alt="" style={{ width: "5rem" }} />
                        </a>
                      </div>
                      <div><ul><li style={{color:"white", marginTop:"10px"}}>
                              Rowan@rwlcontracting.com
                          </li></ul></div>
                      {/* <div class="footer-tittle">
                        <div class="footer-pera">
                          <div style={{ color: "rgb(232, 232, 232)" }}>
                            Transforming Spaces, Building Dreams – RWL
                            Contracting
                          </div>
                        </div>
                        <ul class="mb-40">
                          <li class="number">
                            <a href="#" style={{ color: "white" }}>
                              250-863-1229
                            </a>
                          </li>
                          <li class="number2">
                            <a href="#" style={{ color: "white" }}>
                              Rowan@rwlcontracting.com
                            </a>
                          </li>
                        </ul>
                      </div> */}
                    </div>
                  </div>
                </div>
                {/* <div class="offset-xl-1 col-xl-2 col-lg-2 col-md-4 col-sm-6" style={{marginTop:'40px'}} >
                                    <div class="single-footer-caption mb-50">
                                        <div class="footer-tittle">
                                            <h4>Navigation</h4>
                                            <ul>
                                                <li  style={{cursor:'pointer',marginTop:'20px'}}  ><a onClick={() => nextpage('/')} >Home</a></li>
                                                <li  style={{cursor:'pointer'}}  ><a onClick={() => nextpage('/about')}>About</a></li>
                                                <li  style={{cursor:'pointer'}} ><a onClick={() => nextpage('/services')}>Services</a></li>
                                                <li  style={{cursor:'pointer'}} ><a onClick={() => nextpage('/contact')}>Contact</a></li>
                                                <li  style={{cursor:'pointer'}} ><a onClick={() => nextpage('/gallery')}>Projects</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div> */}
                {/* <div class="col-xl-2 col-lg-2 col-md-4 col-sm-6"  style={{marginTop:'40px'}} >
                                    <div class="single-footer-caption mb-50">
                                        <div class="footer-tittle">
                                            <h4>Services</h4>
                                            <ul>
                                                <li style={{cursor:'pointer'}} ><a onClick={gotoservice} >New Construction</a></li>
                                                <li style={{cursor:'pointer'}} ><a onClick={gotoservice} >Renovation</a></li>
                                                <li style={{cursor:'pointer'}} ><a onClick={gotoservice} >Design-Build</a></li>
                                                <li style={{cursor:'pointer'}} ><a onClick={gotoservice} >Home Additions</a></li>
                                                <li style={{cursor:'pointer'}} ><a onClick={gotoservice} >Project Management</a></li>
                                                <li style={{cursor:'pointer'}} ><a onClick={gotoservice} >More</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div> */}
                {/* <div class="col-xl-2 col-lg-3 col-md-4 col-sm-6"  style={{marginTop:'40px'}} >
                                    <div class="single-footer-caption mb-50">
                                        <div class="footer-social">
                                            <a href="#"><i class="fab fa-twitter"></i></a>
                                            <a href="https://bit.ly/sai4ull"><i class="fab fa-facebook-f"></i></a>
                                            <a href="#"><i class="fab fa-pinterest-p"></i></a>
                                        </div>
                                    </div>
                                    <aside class="insta-in-footer-main ">

                                        <ul class="insta-in-footer"  data-aos="zoom-in" >

                                            {
                                                instafeed
                                            }
                                        </ul>
                                    </aside>

                                </div> */}
              </div>
            </div>
          </div>
          {/* <!-- footer-bottom area --> */}
          <div class="footer-bottom-area">
            <div class="container">
              <div class="footer-border">
                <div class="row">
                  <div class="col-xl-12 ">
                    <div class="footer-copy-right text-center">
                      <div
                        style={{
                          color: "rgb(232, 232, 232)  ",
                          marginBottom: "40px",
                        }}
                      >
                        Made with <span>&hearts;</span> by{" "}
                        <a
                          href="https://digiunction.com/"
                          target="_blank"
                          style={{ color: "white" }}
                        >
                          Digiunction
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- Footer End--> */}
        </div>
      </footer>
    </>
  );
};

export default Fotter;
