import { React, useState, useEffect } from 'react'
import { Carousel, Button } from 'antd';
import './Clientreview.css'

const Clientreview = () => {

  const subHeading = 'Static Subheading'; // Define the static subheading

  // Replace this with your dynamic data
  const sliderData = [
    {
      img:'https://plus.unsplash.com/premium_photo-1669703777435-ce0cfcde3bbf?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8bWVuJTIwZmFzaGlvbnxlbnwwfHwwfHx8MA%3D%3D&auto=format&fit=crop&w=500&q=60',
      name: 'Json Roy',
      message: ' Exceptional kitchen transformation! Exceeded my expectations  ',
    },
    {
      img:'https://images.unsplash.com/photo-1610384104075-e05c8cf200c3?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8N3x8bWVuJTIwZmFzaGlvbnxlbnwwfHwwfHx8MA%3D%3D&auto=format&fit=crop&w=500&q=60',
      name: 'Edie Broke',
      message: 'My bathroom is now a true sanctuary, thanks to them. Highly recommended',
    },
    {
      img:'https://images.unsplash.com/photo-1607345366928-199ea26cfe3e?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTF8fG1lbiUyMGZhc2hpb258ZW58MHx8MHx8fDA%3D&auto=format&fit=crop&w=500&q=60',
      name: 'Smith',
      message: 'Impressive kitchen transformation that exceeded our expectations.',
    },
    {
      img:'https://images.unsplash.com/photo-1536766820879-059fec98ec0a?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MjB8fG1lbiUyMGZhc2hpb258ZW58MHx8MHx8fDA%3D&auto=format&fit=crop&w=500&q=60',
      name: 'Jonathon',
      message: 'Our stunning bathroom remodel left us speechless. A true work of art ',
    },

    // Add more slides as needed
  ];

  // const category = [
  //     { name: 'nature' }, { name: 'hacking' }, { name: 'swiming' }, { name: 'learning' }, { name: 'beautiy' },
  //     { name: 'managment' }, { name: 'house' }, { name: 'sweets' }, { name: 'darknights' }, { name: 'space' },
  //     { name: 'desert' }, { name: 'delight' }, { name: 'gardning' }, { name: 'Photograher' }, { name: 'sea' },
  //     { name: 'informationTeachnology' }, { name: 'university' }, { name: 'naturpsychologye' }, { name: 'parks' }, { name: 'cambridgeuniversity' },
  //     { name: 'nations' }, { name: 'life' }, { name: 'eatables' }, { name: 'digitalworld' }, { name: 'seasons' },
  //     { name: 'students' }, { name: 'manufacturing' }, { name: 'Electricdevices' }, { name: 'yourt' }, { name: 'spacephysics' },
  //     { name: 'nature' }, { name: 'nature' }, { name: 'nature' }, { name: 'nature' }, { name: 'nature' },
  //     { name: 'science' }, { name: 'visions' }, { name: 'sport' }, { name: 'mechanical' }, { name: 'Enginers' },
  // ]

  // const CategoryReturn = category.map((title) => {
  //     return (
  //         <>
  //             <div className='story_cate' >{title.name}</div>
  //         </>
  //     )
  // })



  const [activeIndex, setActiveIndex] = useState(0);
  const [shouldFadeOut, setShouldFadeOut] = useState(false);

  const handleNextSlide = () => {
    setShouldFadeOut(true); // Trigger fading when changing the slide
    setTimeout(() => {
      setActiveIndex((prevIndex) => (prevIndex + 1) % sliderData.length);
      setShouldFadeOut(false); // Reset fading
    }, 1000); // Change slide after 1 second (you can adjust this timing)
  };

  const handlePrevSlide = () => {
    setShouldFadeOut(true); // Trigger fading when changing the slide
    setTimeout(() => {
      setActiveIndex((prevIndex) =>
        prevIndex === 0 ? sliderData.length - 1 : prevIndex - 1
      );
      setShouldFadeOut(false); // Reset fading
    }, 1000);
  };

  return (
    <>
      <div className="testimonials_header">
        <Carousel
          autoplay={true}
          effect="fade"
          beforeChange={() => setShouldFadeOut(true)}
          afterChange={() => setShouldFadeOut(false)}
        >
          {sliderData.map((slide, index) => (
            <div
              key={index}
              className={`testimonials-slider-item ${index === activeIndex ? 'testimonial-active ' : ''}`}
            >
              <div className="testimonial-curve"></div>
              <img src={slide.img} className={`${shouldFadeOut ? 'fade-out' : ''}`} />
              <h1 className='testimonail-name' >{slide.name}</h1>
              <p className='testimonail-p' >their message</p>
              <div className="testimonial-caption">
                <h1 className={`testimonial-main-heading ${shouldFadeOut ? 'fade-right' : ''} `}>
                  <span>" </span>
                  {slide.message}
                  <span> "</span>
                </h1>
              </div>
            </div>
          ))}
        </Carousel>
        
      </div>
    </>
  )
}

export default Clientreview